var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"caseStudies"},[_c('b-container',[_c('h3',{staticClass:"CProjects"},[_vm._v("Current Projects")]),_c('b-row',_vm._l((_vm.cases),function(oneCase,index){return _c('b-col',{directives:[{name:"show",rawName:"v-show",value:(oneCase.acf.category === 'Current Projects'),expression:"oneCase.acf.category === 'Current Projects'"}],key:index,attrs:{"lg":"4","md":"6"}},[_c('div',{staticClass:"post"},[_c('div',[_c('a',{attrs:{"href":"#"}},[_c('div',{staticClass:"thumb",style:('background-image:url(' + oneCase.acf.thumbnail + ');')})])]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(oneCase.acf.client))]),_c('span',{staticClass:"meta"},[_vm._v(_vm._s(oneCase.acf.project_type))]),_c('b-button',{staticClass:"c2a",attrs:{"to":{
              path: 'case-studies/'+ oneCase.acf.client,
              query: { data: oneCase}
            }}},[_vm._v("Read more")])],1)])}),1),_c('h3',{staticClass:"CProjects"},[_vm._v("Completed Projects")]),_c('b-row',_vm._l((_vm.cases),function(oneCase,index){return _c('b-col',{directives:[{name:"show",rawName:"v-show",value:(oneCase.acf.category === 'Completed Projects'),expression:"oneCase.acf.category === 'Completed Projects'"}],key:index,attrs:{"lg":"4","md":"6"}},[_c('div',{staticClass:"post"},[_c('div',[_c('a',{attrs:{"href":"#"}},[_c('div',{staticClass:"thumb",style:('background-image:url(' + oneCase.acf.thumbnail + ');')})])]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(oneCase.acf.client))]),_c('span',{staticClass:"meta"},[_vm._v(_vm._s(oneCase.acf.project_type))]),_c('b-button',{staticClass:"c2a",attrs:{"to":{
              path: 'case-studies/'+ oneCase.acf.client,
              query: { data: oneCase}
            }}},[_vm._v("Read more")])],1)])}),1)],1),_c('div',{staticClass:"csSite"},[_c('b-container',[_c('b-row',[_c('b-col',{attrs:{"lg":"10","md":"10"}},[_c('div',{staticClass:"popOver"},[_c('span',{staticClass:"cite d_block"},[_vm._v(" "+_vm._s(_vm.wpFields.cite)+" ")]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.wpFields.cite_author))])])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }