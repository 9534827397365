<template>
  <div class="caseStudies">
    <b-container>
      <h3 class="CProjects">Current Projects</h3>
      <b-row>
        <b-col lg="4" md="6" v-for="(oneCase, index) in cases" :key="index" v-show="oneCase.acf.category === 'Current Projects'">
          <div class="post">
            <div>
              <a href="#">
                <div
                  class="thumb"
                  :style="
                    'background-image:url(' + oneCase.acf.thumbnail + ');'
                  "
                ></div>
              </a>
            </div>
            <span class="title">{{ oneCase.acf.client }}</span>
            <span class="meta">{{ oneCase.acf.project_type }}</span>
            <b-button :to="{
                path: 'case-studies/'+ oneCase.acf.client,
                query: { data: oneCase}
              }" class="c2a">Read more</b-button>
          </div>
        </b-col>
      </b-row>
      <h3 class="CProjects">Completed Projects</h3>
      <b-row>
        <b-col lg="4" md="6" v-for="(oneCase, index) in cases" :key="index" v-show="oneCase.acf.category === 'Completed Projects'">
          <div class="post">
            <div>
              <a href="#">
                <div
                  class="thumb"
                  :style="
                    'background-image:url(' + oneCase.acf.thumbnail + ');'
                  "
                ></div>
              </a>
            </div>
            <span class="title">{{ oneCase.acf.client }}</span>
            <span class="meta">{{ oneCase.acf.project_type }}</span>
            <b-button :to="{
                path: 'case-studies/'+ oneCase.acf.client,
                query: { data: oneCase}
              }" class="c2a">Read more</b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div class="csSite">
      <b-container>
        <b-row>
          <b-col lg="10" md="10">
            <div class="popOver">
                <span class='cite d_block'>
                    {{wpFields.cite}}
                </span>
                <br>
                <span>{{wpFields.cite_author}}</span>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "caseStudies",
  props: ["wpFields"],
  data() {
    return {
      cases: [],
    };
  },
  mounted() {
    axios
      .get("https://gpplumbingandheating.com/wp-json/wp/v2/case-study?per_page=20")
      .then((response) => {
        this.cases = response.data;
      });
  },
};
</script>

<style scoped>
.CProjects {
  margin-bottom: 20px;
}
.caseStudies {
  padding: 50px 0 0 0;
}
.post {
  margin-bottom: 3rem;
}
.thumb {
  background-size: cover;
  background-position: center;
  width: 100%;
  padding-bottom: 66.66%;
  margin-bottom: 1.5rem;
}
.title {
  display: block;
  font-size: 1.2rem;
  color: #00314a;
}
.meta {
  display: block;
  margin-bottom: 1.5rem;
}
.c2a {
  padding: 0.5rem 2rem !important;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
  color: #fff;
  background-color: #00c196;
  border-color: #00c196;
  width: 100%;
}
.c2a:hover {
  background-color: #009b78;
  border-color: #008e6e;
}
.csSite {
  background: linear-gradient(to left, transparent 50%, #0079c1 50%);
  margin-top:50px;
}
.popOver {
  z-index: 999;
  background-color: #0079c1;
  padding: 3rem 3rem 3rem 0;
  position: relative;
  color: #ffffff;
  width: 100%;
  font-style: italic;
  font-family: "Roboto", sans-serif;
}
.popOver::after{
    content: "";
    width: 100%;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    border-bottom: 50px solid #0079c1;
    border-right: 50px solid #ffffff;
}
.cite {
  font-size: 1.4rem;
}
</style>
