<template>
  <div>
    <Jumbo :wpFields="info.acf" />
    <TopSec :wpFields="info.acf" />
    <div class="gallery">
      <b-container>
        <b-row>
          <b-col lg="4" md='4' v-for="(pic, index) in info.acf.gallery" :key="index">
            <b-img class="gallImg" :src="pic.url"></b-img>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="details">
      <b-container>
        <b-row>
          <b-col md='6'>
            <span class="desc SingleCase" v-html="info.acf.project_info"></span>
            <b-img
              class="accredImg"
              src="https://gpplumbingandheating.com/wp-content/themes/gpplumbingandheating/assets/accreditations.png"
            ></b-img>
            <b-button
              :to="{
                name: 'case-studies',
                query: {
                  id: 15,
                },
              }"
              class="c2a"
              >Back to all case studies</b-button
            >
          </b-col>
          <b-col md='6'>
            <span class="desc SingleCasedesk" v-html="info.acf.project_info"></span>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class='gallery grey'>
      <b-container>
        <Form />
      </b-container>
    </div>
  </div>
</template>

<script>
import Jumbo from "@/components/jumbo.vue";
import TopSec from "@/components/topSec.vue";
import Form from "@/components/form.vue";
export default {
  name: "singlePage",
  components: { Jumbo, TopSec, Form },
  data() {
    return {
      info: this.$route.query.data,
    };
  },
};
</script>

<style scoped>
.gallery {
  padding: 4rem 0;
}
.details{
  padding: 4rem 0;
}
.gallImg {
  margin-bottom: 1rem;
}
.accredImg {
  max-width: 90%;
  height: auto;
  margin-bottom: 4rem;
  margin-top: 2rem;
}
.c2a {
  padding: 0.5rem 2rem !important;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
  color: #fff;
  background-color: #00c196;
  border-color: #00c196;
}
.c2a:hover {
  background-color: #009b78;
  border-color: #008e6e;
}
::v-deep .desc > p {
  font-weight: bold;
}
.grey{
  background-color: #f2f2f2;
}
@media only screen and (min-width: 426px) {
  span.desc.SingleCasedesk {
    display: none;
  }
}
@media only screen and (max-width: 425px) {
  .c2a{
    margin-bottom: 20px;
  }
  span.desc.SingleCasedesk {
    display: none;
  }
}
</style>
