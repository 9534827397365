<template>
  <div class="teamPage">
    <b-container>
      <h2>
        Our Team
      </h2>
      <p>
        We employ over 120 heating engineers who are overseen by our own site
        foreman and management team.
      </p>
      <b-row>
        <b-col
          lg="3"
          md='6'
          cols='6'
          v-for="(team, index) in members.slice().reverse()"
          :key="index"
        >
          <div class="member">
            <div class="overlay">
              <div class="avatar">
                <div class="effect">
                  <div class="meta">
                    <span>
                      Contact {{ team.acf.short_name }} on
                      <br />
                      {{ team.acf.phone_number }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <span class='name'>
                {{team.title.rendered}}
            </span>
            <span class='title'>{{team.acf.job_title}}</span>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "team",
  data() {
    return {
      members: [],
    };
  },
  mounted() {
    axios
      .get("https://gpplumbingandheating.com/wp-json/wp/v2/team?per_page=20")
      .then((response) => {
        this.members = response.data;
      });
  },
};
</script>

<style scoped>
.teamPage {
  background-color: #f2f2f2;
  padding: 4rem 0;
}
.avatar {
  background-image: url(https://gpplumbingandheating.com/wp-content/themes/gpplumbingandheating/assets/avatar-placeholder.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  padding-bottom: 100%;
  margin-bottom: 1.5rem;
}
.effect {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(0, 193, 150, 0.7);
}
.effect:hover {
  opacity: 1;
}
.overlay {
  position: relative;
}
.meta {
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.member {
  margin-bottom: 2.5rem;
}
.name{
  display: block;
  font-weight: 400;
  font-size: 1.3rem;
  color: #00314a;
}
.title{
  display: block;
  font-size: 1.2rem;
}
</style>
