<template>
<div>
  <div>
    <Jumbo :wpFields="wpFields.acf"/>
  </div>
  <div v-if="this.$route.name === 'about-us' || this.$route.name === 'our-services' || this.$route.name === 'contact-us' || this.$route.name === 'careers'">
    <TopSec :wpFields="wpFields.acf"/>
  </div>
  <div v-if="this.$route.name === 'about-us'">
    <Team />
  </div>
  <div v-if="this.$route.name === 'about-us'">
    <Awards :wpFields="wpFields.acf"/>
  </div>
  <div v-if="this.$route.name === 'our-services'">
    <Services />
  </div>
  <div v-if="this.$route.name === 'case-studies'">
    <Case :wpFields="wpFields.acf"/>
  </div>
  <div v-if="this.$route.name === 'contact-us'" class="grey">
    <b-container>
    <Form />
    </b-container>
  </div> 
</div>
</template>

<script>
import axios from "axios";
import Jumbo from "@/components/jumbo.vue";
import TopSec from "@/components/topSec.vue";
import Services from '@/components/services.vue'
import Team from '@/components/team.vue'
import Awards from '@/components/awards.vue'
import Case from '@/components/case.vue'
import Form from '@/components/form.vue'
export default {
  name: "mainPages",
  components: { Jumbo, TopSec, Services, Team, Awards, Case, Form },
  data() {
    return {
      wpFields: [],
    };
  },
  mounted() {
    axios
      .get(
        "https://gpplumbingandheating.com/wp-json/wp/v2/pages/" +
          this.$route.query.id
      )
      .then((response) => {
        this.wpFields = response.data;
      });
  },
};
</script>

<style scoped>
h3 {
    font-weight: 300 !important;
}
h3, .h3 {
    font-size: 1.75rem;
}
.grey{
  background-color: #f2f2f2;
  padding: 4rem 0;
}
</style>