<template>
  <div class="awards">
    <b-container>
      <span id="accred" class="anchor"></span>
      <h2>
        Our Accreditations
      </h2>
      <span v-html="wpFields.accreditations_paragraph"></span>
      <b-row>
        <b-col lg="6">
          <div class="accordion" role="tablist">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button v-b-toggle.accordion-1 variant="info"
                  >{{ accred[0].title.rendered
                  }}<span class="plus">+</span><span class="minus">-</span></b-button
                >
              </b-card-header>
              <b-collapse
                id="accordion-1"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>
                    <b-row>
                      <b-col>
                        <a :href="accred[0].acf.document">
                          <b-img
                            class="credImg"
                            :src="accred[0].acf.display_image"
                          ></b-img>
                        </a>
                      </b-col>
                      <b-col>
                        <span v-html="accred[0].acf.description"></span>
                        <span class="reg"
                          >Registration No.
                          <span
                            v-html="accred[0].acf.registration_number"
                          ></span
                        ></span>
                      </b-col>
                    </b-row>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
        <b-col lg="6">
          <div class="accordion" role="tablist">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button v-b-toggle.accordion-2 variant="info"
                  >{{ accred[1].title.rendered
                  }}<span class="plus">+</span><span class="minus">-</span></b-button
                >
              </b-card-header>
              <b-collapse
                id="accordion-2"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>
                    <b-row>
                      <b-col>
                        <a :href="accred[1].acf.document">
                          <b-img
                            class="credImg"
                            :src="accred[1].acf.display_image"
                          ></b-img>
                        </a>
                      </b-col>
                      <b-col>
                        <span v-html="accred[1].acf.description"></span>
                        <span class="reg"
                          >Registration No.
                          <span
                            v-html="accred[1].acf.registration_number"
                          ></span
                        ></span>
                      </b-col>
                    </b-row>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
        <b-col lg="6">
          <div class="accordion" role="tablist">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button v-b-toggle.accordion-3 variant="info"
                  >{{ accred[2].title.rendered
                  }}<span class="plus">+</span><span class="minus">-</span></b-button
                >
              </b-card-header>
              <b-collapse
                id="accordion-3"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>
                    <b-row>
                      <b-col>
                        <a :href="accred[2].acf.document">
                          <b-img
                            class="credImg"
                            :src="accred[2].acf.display_image"
                          ></b-img>
                        </a>
                      </b-col>
                      <b-col>
                        <span v-html="accred[2].acf.description"></span>
                        <span class="reg"
                          >Registration No.
                          <span
                            v-html="accred[2].acf.registration_number"
                          ></span
                        ></span>
                      </b-col>
                    </b-row>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
        <b-col lg="6">
          <div class="accordion" role="tablist">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button v-b-toggle.accordion-4 variant="info"
                  >{{ accred[3].title.rendered
                  }}<span class="plus">+</span><span class="minus">-</span></b-button
                >
              </b-card-header>
              <b-collapse
                id="accordion-4"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>
                    <b-row>
                      <b-col>
                        <a :href="accred[3].acf.document">
                          <b-img
                            class="credImg"
                            :src="accred[3].acf.display_image"
                          ></b-img>
                        </a>
                      </b-col>
                      <b-col>
                        <span v-html="accred[3].acf.description"></span>
                        <span class="reg"
                          >Registration No.
                          <span
                            v-html="accred[3].acf.registration_number"
                          ></span
                        ></span>
                      </b-col>
                    </b-row>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
        <b-col lg="6">
          <div class="accordion" role="tablist">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button v-b-toggle.accordion-5 variant="info"
                  >{{ accred[4].title.rendered
                  }}<span class="plus">+</span><span class="minus">-</span></b-button
                >
              </b-card-header>
              <b-collapse
                id="accordion-5"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>
                    <b-row>
                      <b-col>
                        <a :href="accred[4].acf.document">
                          <b-img
                            class="credImg"
                            :src="accred[4].acf.display_image"
                          ></b-img>
                        </a>
                      </b-col>
                      <b-col>
                        <span v-html="accred[4].acf.description"></span>
                        <span class="reg"
                          >Registration No.
                          <span
                            v-html="accred[4].acf.registration_number"
                          ></span
                        ></span>
                      </b-col>
                    </b-row>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div class="awards">
      <b-container>
        <span id="award" class="anchor"></span>
        <h2>
          Our Awards
        </h2>
        <span v-html="wpFields.awards_paragraph"></span>
              <b-row>
        <b-col lg="6">
          <div class="accordion" role="tablist">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button v-b-toggle.accordion-6 variant="info"
                  >{{awards[0].title.rendered}}<span class="plus awardsplus">+</span><span class="minus">-</span></b-button
                >
                
              </b-card-header>
              <b-collapse
                id="accordion-6"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>
                    <b-row>
                      <b-col>
                        <a :href="awards[0].acf.document">
                          <b-img
                            class="credImg"
                            :src="awards[0].acf.display_image"
                          ></b-img>
                        </a>
                      </b-col>
                      <b-col>
                        <span v-html="awards[0].acf.description"></span>
                      </b-col>
                    </b-row>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
        <b-col lg="6">
          <div class="accordion" role="tablist">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button v-b-toggle.accordion-7 variant="info"
                  >{{titleHTML(awards[1].title.rendered)}}<span class="plus awardsplus">+</span><span class="minus">-</span></b-button
                >
                
              </b-card-header>
              <b-collapse
                id="accordion-7"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>
                    <b-row>
                      <b-col>
                        <a :href="awards[1].acf.document">
                          <b-img
                            class="credImg"
                            :src="awards[1].acf.display_image"
                          ></b-img>
                        </a>
                      </b-col>
                      <b-col>
                        <span v-html="awards[1].acf.description"></span>
                      </b-col>
                    </b-row>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
              </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "awards",
  props: ["wpFields"],
  data() {
    return {
      accred: [],
      awards: [],
    };
  },
  methods:{
        titleHTML(item) {
      var low = item;
      var rep = low.replace(/&#038;/g, "&");
      return rep;
    },
  },
  mounted() {
    axios //add API Call
      .get("https://gpplumbingandheating.com/wp-json/wp/v2/award?per_page=20") // Page number changed on each page based on JSON page id you are calling
      .then((response) => {
        this.awards = response.data;
        return axios.get(
          "https://gpplumbingandheating.com/wp-json/wp/v2/accreditation?per_page=20"
        );
      })
      .then((response) => {
        this.accred = response.data;
      });
  },
};
</script>

<style scoped>
.awardplusSecond {
  position: absolute;
  right: 30px;
  top: 50px;
  font-size: 16pt;
}
span.plus.awardsplus {
  position: absolute;
  right: 30px;
  top: 20px;
  font-size: 16pt;
}
.awards {
  background-color: #fff;
  padding: 50px 0;
}
.btn-info {
  font-size: 1.2rem;
  display: block !important;
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  padding: 1rem 1.5rem;
  color: #00314a;
}

.card-header {
  background-color: transparent;
  border: solid 1px #dddddd;
  margin-bottom: 1rem;
}
.credImg {
  width: 150px;
  height: auto;
}
.reg {
  color: #00314a;
}
.plus {
  float: right;
  color: #0079c1;
  font-size: 15pt;
}
.minus {
  float: right;
  color: #0079c1;
  font-size: 15pt;
}
.collapsed>.plus{
  display: block;
}
.collapsed>.minus{
  display: none;
}
.not-collapsed>.plus{
  display: none;
}
.not-collapsed>.minus{
  display: block;
}
</style>
