<template>
  <div class="request-a-callback-form">
    <div>
      <h3 v-if="this.$route.name === 'contact-us'" class="getintouch">Get in touch today</h3>
      <h3 v-else class="getintouch">Something similar? Get in touch</h3>
    </div>
    <transition name="fade" mode="out-in">
      <div v-show="sent">
        <p>Thanks for contacting us, we will get back to you shortly...</p>
      </div>
    </transition>

    <form @submit="onSubmit">
      <b-row>
        <b-col md='6'>
          <input type="text" v-model="names" placeholder="Full Name" />
          <br />

          <input type="text" v-model="email" placeholder="Email" />
          <br />

          <input type="text" v-model="company" placeholder="Company" />
        </b-col>
        <b-col md='6'>
          <textarea v-model="message" rows="7" cols="1" placeholder="Enquiry" class="form-control">
          </textarea>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col style="text-align:center;">
          <button data-text="submit" type="submit" class="c2a">
            Send your message
          </button>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Form",
  data() {
    return {
      sent: false,
      contact: true,
      names: "",
      email: "",
      company: "",
      message: "",
    };
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      const formData = new FormData();
      formData.append("names", this.names);
      formData.append("email", this.email);
      formData.append("company", this.company);
      formData.append("message", this.message);
      console.log(formData);
      axios
        .post(
          "https://gpplumbingandheating.com/wp-json/contact-form-7/v1/contact-forms/227/feedback",
          formData
        )
        .then((response) => {
          this.sent = true;
          return response;
        });
    },
  },
};
</script>

<style scoped>
h3.getintouch {
    margin-bottom: 2rem;
    color: #969696;
    text-align: center;
    font-weight: 400;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-active {
  transition-delay: 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.c2a {
  padding: 0.5rem 2rem !important;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
  color: #fff;
  background-color: #00c196;
  border-color: #00c196;
  border: 0;
}
.c2a:hover {
  background-color: #009b78;
  border-color: #008e6e;
}
input {
  width: 100%;
  padding: 1rem 1rem !important;
  border: none;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  color: #cccccc;
}
textarea {
  overflow: auto;
  resize: vertical;
  width: 100%;
  padding: 1rem 1rem !important;
  border: none;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  color: #cccccc;
}
</style>
